export default function ExportConfigXml(apiUrl, brokerPrefix, urlAccessCode, hubId, clientId, cryptedPassword,language) {
    let result = ""
    const newLine = "\r\n"

    result += '<?xml version="1.0" encoding="utf-8"?>' + newLine
    result += '<configuration>' + newLine

    result += '<configSections>' + newLine
    result += '<sectionGroup name="userSettings" type="System.Configuration.UserSettingsGroup, System, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089">' + newLine
    result += '<section name="NotifyIcon.Properties.Settings" type="System.Configuration.ClientSettingsSection, System, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089" allowExeDefinition="MachineToLocalUser" requirePermission="false" />' + newLine
    result += '</sectionGroup>' + newLine
    result += '<sectionGroup name="applicationSettings" type="System.Configuration.ApplicationSettingsGroup, System, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089">' + newLine
    result += '<section name="NotifyIcon.Properties.Settings" type="System.Configuration.ClientSettingsSection, System, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089" requirePermission="false" />' + newLine
    result += '</sectionGroup>' + newLine
    result += '</configSections>' + newLine

    result += '<startup>' + newLine
    result += '<supportedRuntime version="v4.0" sku=".NETFramework,Version=v4.6.1" />' + newLine
    result += '</startup>' + newLine

    result += '<userSettings>' + newLine
    result += '<NotifyIcon.Properties.Settings>' + newLine
    result += '<setting name="Language" serializeAs="String">' + newLine
    result += `<value>${language}</value>` + newLine
    result += '</setting>' + newLine
    result += '<setting name="UserForceYear" serializeAs="String">' + newLine
    result += '<value>false</value>' + newLine
    result += '</setting>' + newLine
    result += '<setting name="Year" serializeAs="String">' + newLine
    result += `<value>${new Date().getFullYear()}</value>` + newLine
    result += '</setting>' + newLine
    result += '</NotifyIcon.Properties.Settings>' + newLine
    result += '</userSettings>' + newLine

    result += '<applicationSettings>' + newLine
    result += '<NotifyIcon.Properties.Settings>' + newLine
    result += '<setting name="ConnectionString" serializeAs="String">' + newLine
    if (apiUrl) {
        result += `<value>${apiUrl}/</value>` + newLine
    } else {
        result += `<value />` + newLine
    }
    result += '</setting>' + newLine
    result += '<setting name="ServiceAddress" serializeAs="String">' + newLine
    result += '<value>127.0.0.1</value>' + newLine
    result += '</setting>' + newLine
    result += '<setting name="ServicePort" serializeAs="String">' + newLine
    result += '<value>3005</value>' + newLine
    result += '</setting>' + newLine
    result += '<setting name="CompanyId" serializeAs="String">' + newLine
    result += `<value>${urlAccessCode}</value>` + newLine
    result += '</setting>' + newLine
    result += '<setting name="BarcodeCheckDigit" serializeAs="String">' + newLine
    result += '<value>False</value>' + newLine
    result += '</setting>' + newLine
    result += '<setting name="HubId" serializeAs="String">' + newLine
    result += `<value>${hubId}</value>` + newLine
    result += '</setting>' + newLine
    result += '<setting name="ClientId" serializeAs="String">' + newLine
    result += `<value>${clientId}</value>` + newLine
    result += '</setting>' + newLine
    result += '<setting name="ClientSecret" serializeAs="String">' + newLine
    result += `<value>${cryptedPassword}</value>` + newLine
    result += '</setting>' + newLine
    result += '<setting name="BrokerPrefix" serializeAs="String">' + newLine
    if (brokerPrefix) {
        result += `<value>${brokerPrefix}</value>` + newLine
    } else {
        result += `<value />` + newLine
    }
    result += '</setting>' + newLine
    result += '</NotifyIcon.Properties.Settings>' + newLine
    result += '</applicationSettings>' + newLine

    result += '<runtime>' + newLine
    result += '<assemblyBinding xmlns="urn:schemas-microsoft-com:asm.v1">' + newLine
    result += '<dependentAssembly>' + newLine
    result += '<assemblyIdentity name="Newtonsoft.Json" publicKeyToken="30ad4fe6b2a6aeed" culture="neutral" />' + newLine
    result += '<bindingRedirect oldVersion="0.0.0.0-13.0.0.0" newVersion="13.0.0.0" />' + newLine
    result += '</dependentAssembly>' + newLine
    result += '</assemblyBinding>' + newLine
    result += '</runtime>' + newLine

    result += '</configuration>'

    return result
}